import HomeScreen from "./components/HomeScreen";


function App() {
  return (
    <HomeScreen />
  );
}

export default App;
